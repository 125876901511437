<template>
  <div class="row">
    <div class="column">
      <hr class="divider">
    </div>
  </div>
  <div class="product-variant-buy-list mobile-width row column">
    <div class="padding-small variants-heading">{{ $translate('TEXT_PRODUCT_VARIANTS') }}</div>
    <div 
      v-for="(product, idx) in props.variants"
      :key="product.id"
      class="product"
    > 
      <div class="top">
        <div class="left">

          <div class="image product-overlay">
            <Image
              :source="product.image?.image"
              :estimated-viewport-widths="{small: 40, medium: 20, large: 20}"
            />
          </div>

          <div class="name-model">
            <div class="model">
              {{ $translate('TEXT_MODEL_NUMBER') }} {{ product.model }}
            </div>

            <div>
              {{ product.name }}
            </div>
          </div>
        </div>

        <div class="right">

          <ProductStockStatus 
            :allow-checkout="product.stock_allow_checkout"
            :stock="product.quantity"
          />
          <div class="price-wrapper">
            <div :class="{ old: hasDiscountPrice(product) }">
              {{ product.price }}
            </div>

            <div 
              v-if="hasDiscountPrice(product)" 
                :class="{ 
                  new: hasDiscountPrice(product), 
                  'outlet': props.isoutlet
                }"
              >
              {{ product.price_special }}
            </div>
          </div>
        
        </div>
      </div>
      <div class="bottom">
        <!-- Quantity -->
      <div class="quantity">
        <button 
          @click="products[idx].quantity_diff > 0 && (products[idx].quantity_diff -= +product.package_size)"
          type="button" 
          aria-label="Decrease quantity"
        >
          <MinusIcon />
        </button>
        
        <ProductQuantityInput
          v-model="products[idx].quantity_diff"
          :lowest-number="0"
          :hide-spin-buttons="true"
          :package-size="+product.package_size"
          :step="+product.package_size"
        />

        <button
          @click="products[idx].quantity_diff += +product.package_size"
          type="button"
          aria-label="Increase quantity"
        >
          <PlusIcon />
        </button>
      </div>

        <div class="buy-button">
          <p v-if="errorMessage" class="err">
              {{ $translate('C_CART_OUT_OF_STOCK_MESSAGE') }}
          </p>
          <button 
            type="button"
            class="button primary hollow"
            @click="buyProducts"
          >
            {{ $translate('PRODUCT_BUTTON_BUY') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
const shoppingCartStore = useShoppingCartStore();

const errorMessage = ref();

const props = defineProps({
  variants: {
    required: true,
    type: Array
  },
  isoutlet: {
    required: false,
    type: Boolean
  }
});

// Array for v-model
const products = reactive([]);
props.variants.forEach(product => {
  let mappedProduct = {
    id: product.id,
    quantity_diff: 0,
    package_size: product.package_size
  };
  products.push(mappedProduct);
});

const hasDiscountPrice = (product) => {
  if (product.price_special) {
    return true;
  }
  return false;
};

function buyProducts() {
  let productsToBuy = products.filter(product => product.quantity_diff > 0);

  shoppingCartStore.setProducts(productsToBuy)
    .then(() => {
      // Success action
    })
    .catch(e => {
      errorMessage.value = "En eller flera produkter kunde inte läggas till"
      setTimeout(() => {
        errorMessage.value = null;
      }, 5000);
    })
}
</script>