<template>
  <div>
    <!-- Filter -->
      <div class="account-filter">
        <div class="col">
          <label>{{ $translate('ORDER_NUMBER') }}</label>
          <input v-model="orderNumber" type="text">
        </div>
        
        <div class="col">
          <label>{{ $translate('C_FROM') }}</label>
          <input v-model="filterDateFrom" type="date">
        </div>
        
        <div class="col">
          <label>{{ $translate('C_TO') }}</label>
          <input v-model="filterDateTo" type="date">
        </div>
        
        
        <div class="col">
          <button 
            @click="clearFilters"
            type="button" 
            class="button small expanded clear-btn primary"
            :disabled="clearButtonInactive"
          >
            {{ $translate('BUTTON_CLEAR_FILTERS') }}
          </button>
        </div>
      </div>

      <!-- Credits Open -->
      <div class="order-table-heading-wrapper">
        <div class="table-heading">
          <h3>
            {{ $translate('C_CREDIT_STATUS_OPEN') }} <span class="order-count">({{ filteredActiveOrdersCount }})</span>
          </h3>
        </div>
      </div>
  
      <div v-if="!creditsOpen.length">
        <p>{{ $translate('C_NO_ACTIVE_CREDITS') }}</p>
      </div>
  
      <div v-else>
        <table class="account-table">
          <thead>
            <!-- <th style="width: 30%;">id</th> -->
            <th style="width: 25%;">{{ $translate('C_CREDIT_NUMBER') }}</th>
            <th style="width: 25%;">{{ $translate('C_POSTINGDATE') }}</th>
            <th style="width: 25%;">{{ $translate('ACCOUNT_ORDER_STATUS') }}</th>
            <th style="width: 25%;"></th>
          </thead>
          <tbody>
            <tr v-for="credit in currentPageOpenCreditsList" :key="credit.id">
              <!-- <td>{{ credit.id }}</td> -->
              <td>{{ credit.number }}</td>
              <td>{{ credit.postingDate }}</td>
              <td>{{ credit.status }}</td>
              <td style="text-align: right;">
                <div class="flex-right">
                  <a @click="$emit('show-credit-rows', credit)">
                    <span class="table-link">
                      {{ $translate('HEADER_PRODUCTS_SHOW') }}
                    </span>
                  </a> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
  
        <pagination
          :number-of-pages="numberOfPagesActiveOrders"
          :selected-page="activeOrdersCurrentPage"
          @select-page="setCurrentPageActive($event)"
        ></pagination>
      </div>
  
      <!-- Credits Paid -->
      <div class="order-table-heading-wrapper margin-large-top">
        <div class="table-heading">
          <h3>
            {{ $translate('C_CREDIT_STATUS_PAID') }} <span class="order-count">({{ filteredDeliveredOrdersCount }})</span>
          </h3>
        </div>
      </div>
  
      <div v-if="!creditsPaid.length">
        <p>{{ $translate('C_NO_DELIVERED_ORDERS') }}</p>
      </div>
  
      <div v-else class="table-container">
        <table class="account-table">
          <thead>
            <!-- <th style="width: 30%;">id</th> -->
            <th style="width: 25%;">{{ $translate('C_CREDIT_NUMBER') }}</th>
            <th style="width: 25%;">{{ $translate('C_POSTINGDATE') }}</th>
            <th style="width: 25%;">{{ $translate('ACCOUNT_ORDER_STATUS') }}</th>
            <th style="width: 25%;"></th>
          </thead>
          <tbody>
            <tr v-for="credit in currentPagePaidCreditsList" :key="credit.id">
              <td>{{ credit.number }}</td>
              <td>{{ credit.postingDate }}</td>
              <td>{{ credit.status }}</td>
              <td style="text-align: right;">
                <div class="flex-right">
                  <a @click="$emit('show-credit-rows', credit)">
                    <span class="table-link">
                      {{ $translate('HEADER_PRODUCTS_SHOW') }}
                    </span>
                  </a> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Pagination -->
        <pagination
          :number-of-pages="numberOfPagesDeliveredOrders"
          :selected-page="deliveredOrdersCurrentPage"
          @select-page="setCurrentPageDelivered($event)"
        ></pagination>
      </div>
    </div>
</template>

<script setup>

  import { computed, ref } from "vue";

  const numberOfItemsPerPageActive = ref(10);
  const numberOfItemsPerPageDelivered = ref(10);
  let activeOrdersCurrentPage = ref(1);
  let deliveredOrdersCurrentPage = ref(1);
  let filterDateFrom = ref("");
  let filterDateTo = ref("");
  let orderNumber = ref("");
  let creditList = ref([]);
  let creditsOpen = ref([]); 
  let creditsPaid = ref([]); 

  const clearButtonInactive = computed(() => {
    if (filterDateTo.value || filterDateFrom.value || orderNumber.value) {
      return false;
    }
    return true;
  });

  const numberOfPagesActiveOrders = computed(() => {
    return Math.ceil(filteredActiveOrders.value.length / numberOfItemsPerPageActive.value);
  });

  const numberOfPagesDeliveredOrders = computed(() => {
    // this is used by invoices aswell
    return Math.ceil(filteredDeliveredOrders.value.length / numberOfItemsPerPageDelivered.value);
  });

  const filteredActiveOrdersCount = computed(() => {
    return filteredActiveOrders.value.length;
  });

  const filteredDeliveredOrdersCount = computed(() => {
    // this is used by invoices aswell
    return filteredDeliveredOrders.value.length;
  });

  const filteredActiveOrders = computed(() => {
    if (orderNumber.value) {
      return sortByDate(creditsOpen.value.filter(order => order.number.includes(orderNumber.value)));
    }

    if (filterDateFrom.value || filterDateTo.value) {
      return sortByDate(filterListByDateSpan(creditsOpen.value, filterDateFrom.value, filterDateTo.value));
    }

    return sortByDate(creditsOpen.value);
  });

  const filteredDeliveredOrders = computed(() => {
    if (orderNumber.value) {
      return sortByDate(creditsPaid.value.filter(order => order.number.includes(orderNumber.value)));
    }

    if (filterDateFrom.value || filterDateTo.value) {
      return sortByDate(filterListByDateSpan(creditsPaid.value, filterDateFrom.value, filterDateTo.value));
    }

    return sortByDate(creditsPaid.value);
  });

  const currentPageOpenCreditsList = computed(() => {
    let rangeStart = (activeOrdersCurrentPage.value * numberOfItemsPerPageActive.value) - numberOfItemsPerPageActive.value;
    let rangeEnd = activeOrdersCurrentPage.value * numberOfItemsPerPageActive.value;
    return filteredActiveOrders.value.slice(rangeStart, rangeEnd);
  });

  const currentPagePaidCreditsList = computed(() => {
    let rangeStart = (deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value) - numberOfItemsPerPageDelivered.value;
    let rangeEnd = deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value;
    return filteredDeliveredOrders.value.slice(rangeStart, rangeEnd);
  });

  const filterListByDateSpan = (unfilteredList, filterDateFromValue, filterDateToValue) => {
    return unfilteredList.filter(order => {        
      let filterDateFrom = filterDateFromValue ? new Date(filterDateFromValue) : null;
      filterDateFrom = filterDateFrom ? filterDateFrom.getTime() : Number.NEGATIVE_INFINITY;
      
      let filterDateTo = filterDateToValue ? new Date(filterDateToValue) : null;
      filterDateTo = filterDateTo ? filterDateTo.getTime() : Number.POSITIVE_INFINITY;
      
      let orderDate = new Date(order.postingDate);
      orderDate = orderDate.getTime();
      return orderDate >= filterDateFrom && orderDate <= filterDateTo;
    });
  };

  const setCurrentPageActive = (page) => {
    if (isNaN(page)) {
      return;
    }
    activeOrdersCurrentPage.value = page;
  };

  const setCurrentPageDelivered = (page) => {
    if (isNaN(page)) {
      return;
    }
    deliveredOrdersCurrentPage.value = page;
  };

  const sortByDate = (arr) => {
    return arr.sort((a,b) => {
      let date1 = new Date(a.postingDate).getTime();
      let date2 = new Date(b.postingDate).getTime();
      return (date2 > date1 ? 1 : -1);
    });
  };

  const clearFilters = () => {
    filterDateFrom.value = "";
    filterDateTo.value = "";
    orderNumber.value = "";
  };

  const formatSum = (number) => {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      minimumFractionDigits: 2
    }).format(number);
  };

  const fetchOrderList = async (key) => {
    const orderList = [];
    try {

      const body = new URLSearchParams({key});
      const response = await fetch('/ajax.php?mode=external_fetchdata', {
        method: 'POST',
        body: body
      });

      const data = await response.json();

      data.value.forEach(order => {
        orderList.push(order);
      });

      return orderList;

    } catch (error) {
      console.log(error);
    }
  };

  const fetchCredits = async () => {
    creditList.value = await fetchOrderList('FetchCreditsList');
    creditsOpen.value = creditList.value.filter(credit => credit.status === 'Open');
    creditsPaid.value = creditList.value.filter(credit => credit.status === 'Paid');
  }

  fetchCredits();
</script>