<template>
  <div 
    v-if="overCreditLimit"
    class="invoice-banner-wrapper"
  >
    <ExclamationMark/>
    {{ $translate('C_BANNER_OVER_CREDIT_LIMIT') }}
  </div>
  <div
    v-if="unpaidInvoice"
    class="invoice-banner-wrapper"
  >
    <ExclamationMark/>
    {{ $translate('C_BANNER_OVERDUE_INVOICE') }}
  </div>
</template>

<script setup>
  import { ref, computed, inject } from "vue";
  import ExclamationMark from '@/components/icons/ExclamationMark.vue';
  const { $translate } = inject('i18n');

  let customer = ref(null);
  const overCreditLimit = computed(() => {
    if (!customer.value) {
      return false;
    }
    return (customer.value.creditLimit - customer.value.balanceDue < 0) && customer.value.creditLimit > 0;
  });

  const fetchCustomer = async () => {

    try {
      const body = new URLSearchParams({key: 'FetchCustomer'});
      const response = await fetch('/ajax.php?mode=external_fetchdata', {
        method: 'POST',
        body: body
      });

      const data = await response.json();
      customer.value = data.value[0];
      fetchUnpaidInvoices();
    } catch (error) {
      console.log(error);
    }
  };

  fetchCustomer();

  let invoices = ref(null);
  const unpaidInvoice = computed(() => {
    if (!invoices.value) {
      return false;
    }
    return invoices.value > 0;
  })
  const fetchUnpaidInvoices = async () => {

    try {
      const response = await fetch('/ajax.php?mode=external_fetchdata', {
        method: 'POST',
        body: new URLSearchParams(
          {
            'key': 'FetchUnpaidInvoices',
            'custom_fields': JSON.stringify({
              'now': String(new Date().toISOString())
            })
          })
      });
      const data = await response.json();
      invoices.value = data.value.length;
    } catch (error) {
      console.error(error);
    }
  };
</script>