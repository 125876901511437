<template>
<div class="buy-button-wrapper">
  <div v-if="showQuantityInput" class="input-wrapper">
    <ProductQuantityInput 
      v-model="quantity"
      :lowest-number="props.lowestNumber"
      :package-size="props.packageSize"
      :step="props.packageSize"
    />
    {{ props.unit }}
  </div>

  <div class="button-wrapper">
    <button
      @click="buyProduct"
      type="button"
      class="button primary large expanded"
      :disabled="props.disabled"
    >
      {{ buyButtonText }}
    </button>
  </div>
</div>

<div v-if="errMsg" class="buy-button-error-wrapper">
  <span class="alert-color">{{ errMsg }}</span>
</div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';

const shoppingCartStore = useShoppingCartStore();

const { $translate } = inject('i18n');

const props = defineProps({
  productId: {
    required: false,
    type: Number
  },
  showQuantityInput: {
    required: false,
    type: [Boolean, Number],
    default: false
  },
  buttonText: {
    required: false,
    type: String
  },
  unit: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false
  },
  lowestNumber: {
    required: false,
    type: Number,
    default: 1
  },
  packageSize: {
    required: false,
    type: Number,
    default: 1
  }
});

const productId = ref(props.productId);
const quantity = ref(props.packageSize || 1);
const errMsg = ref('');

const buyButtonText = computed(() => {
  let buttonText = props.buttonText || 'IMAGE_BUTTON_IN_CART';

  if (props.disabled) {
    return $translate('OUT_OF_STOCK');
  }
  return $translate(buttonText);  //$translate('PRODUCT_BUTTON_BUY')
});

const buyProduct = () => {
  const product = {
    id: productId.value,
    quantity_diff: quantity.value,
    attributes: {}
  };

  if (document.querySelector('form[name="add_product"]')) {
    const form = document.querySelector('form[name="add_product"]');
    const formData = new FormData(form);

    for (const [key, value] of formData) {
      product.attributes[key] = value;
    }
  }

  shoppingCartStore.setProducts(product)
    .catch(error => {
      errMsg.value = error;

      setTimeout(() => {
        errMsg.value = null;
      }, 5000);
    })
}
</script>