<template>
  <div class="search-banners-wrapper row">
    <div v-for="searchBanner, index in bannersForViewport" :key="`${index}-${searchBanner.imageurl}`" class="column">
      <a :href="searchBanner.clickurl" :target="searchBanner.clicktarget">
        <Image
          :source="searchBanner.imageurl"
          :estimated-viewport-widths="{small: 90, medium: 90, large: 90}"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import Image from '@/components/Image.vue';

export default {
  props: {
    searchBanners: {
      type: Array,
      required: true
    },
    desktopBreakpoint: {
      type: Number,
      required: true
    }
  },
  components: {
    Image
  },
  setup(props) {
    const windowWidth = ref(window.innerWidth);
    const resized = ref(false);
    const resizeIntervalId = ref(null);

    const handleResize = () => resized.value = true;

    onMounted(() => {
      resizeIntervalId.value = setInterval(() => {
        if (resized.value) {
          windowWidth.value = window.innerWidth;
          resized.value = false;
        }
      }, 500);

      window.addEventListener('resize', handleResize);
      
      onUnmounted(() => {
        clearInterval(resizeIntervalId.value);
        window.removeEventListener('resize', handleResize);
      });
    });

    const bannersForViewport = computed(() => {
      const isDesktop = windowWidth.value >= props.desktopBreakpoint;
      return props.searchBanners.filter(banner => 
        (!isDesktop && banner.type === 'Mobile') || 
        (isDesktop && banner.type === 'Desktop')
      );
    });
    
    return {
      bannersForViewport
    };
  }
}
</script>