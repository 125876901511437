<template>
  <div class="account-order-history-wrapper">
    <div v-if="pagesRange.length > 1" class="vue-pagination-wrapper row column">
      <div v-for="pageNumber in pagesRange" :key="pageNumber">
        <a 
          @click="$emit('select-page', pageNumber)"
          :class="{ active : pageNumber == selectedPage }"
        >
          {{ pageNumber }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue';

  export default {
    template: '#Pagination',

    props: {
      numberOfPages: {
        type: Number,
        required: true
      },
      selectedPage: {
        type: Number,
        required: true
      }
    },

    setup(props) {
      const pagesRange = computed(() => {
        if (props.numberOfPages > 10) {
          let rangeStart = props.selectedPage - 1;
          let rangeEnd = props.selectedPage + 2;

          if (rangeStart < 1) rangeStart = 1;
          if (props.numberOfPages - props.selectedPage <= 2) rangeStart -= 2;
          if (rangeEnd > props.numberOfPages) rangeEnd = props.numberOfPages;

          let pageRange = [];
          if (rangeStart == 2) pageRange = [1];
          else if (rangeStart > 2) pageRange = [1, '...'];

          for (let i = rangeStart; i <= rangeEnd; i++) pageRange.push(i);

          if (!pageRange.includes(props.numberOfPages)) {
            pageRange.push('...');
            pageRange.push(props.numberOfPages);
          }

          return pageRange;
        }
        
        return Array.from({ length: props.numberOfPages }, (_, i) => i + 1);
      });

      return { pagesRange };
    }
  };
</script>
