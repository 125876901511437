<template>
  <div class="header-favorite-icon-wrapper">
    <a 
      :href="favoritesHref" 
      :title="favoritesPage" 
      data-favorite-animation
      class="icon"
    >
      <HeartIcon
        v-show="customerProductListsStore.totalAmountOfFavorites < 1"
      />
      
      <SolidHeartIcon
        v-show="customerProductListsStore.totalAmountOfFavorites > 0"
      />

      <span v-if="showLabel || showCounter" class="title">
        <span v-if="showLabel">{{ $translate('TEXT_MY_FAVORITES') }}</span> <span v-if="showCounter">({{ customerProductListsStore.totalAmountOfFavorites }})</span>
      </span>
    </a>
  </div>
</template>

<script>
import HeartIcon from '@/assets/icons/heart.svg?component';
import SolidHeartIcon from '@/assets/icons/heart-solid.svg?component';
import { useCustomerProductLists } from '@/stores/customer-products-list.js';

export default {
  props: {
    favoritesHref: {
      required: true,
      type: String,
    },
    favoritesPage: {
      required: false,
      type: String,
    },
    showLabel: {
      required: false,
      type: Boolean
    },
    showCounter: {
      required: false,
      type: Boolean
    }
  },
  components: {
    HeartIcon,
    SolidHeartIcon
  },
  setup(props) {
    const customerProductListsStore = useCustomerProductLists();
    
    const favoritesHref= props.favoritesHref;
    const favoritesPage = props.favoritesPage;
    const showLabel = props.showLabel;
    const showCounter = props.showCounter;
    
    return {
      showLabel,
      showCounter,
      customerProductListsStore,
      favoritesHref,
      favoritesPage,
      HeartIcon,
      SolidHeartIcon
    }
  }
}
</script>