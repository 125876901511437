<template>
  <div class="listing-sort-widget">
    <span class="number-of-found-products">
      {{ productCount }} {{ productCountText }}
    </span>
    
    <div v-if="sortOptions" class="sort-wrapper" data-sort-menu>
      <button 
        @click="toggleSortDropdown" 
        :class="{ open : showSortDropdown }"
      >
        <span>
          <span>{{ sortByText }} </span>
          {{ sortByValue }}
        </span>
        <ChevronDownIcon />
      </button>

      <div 
        v-show="showSortDropdown"
        class="sort-dropdown"
      >
        <div v-for="(option, idx) in sortOptions">
          <input 
            type="radio"
            v-model="selectedSortOrder"
            name="sort_by"
            :value="option"
            :id="idx"
          >
          <label :for="idx">
            <span>
              {{ option.label }}
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useListingSortWidget} from '@/composables/listing-sort-widget.js';

const props = defineProps({
    sortOrder: String,
    sortBy: String,
    sortOptionsTwig: Object,
    sortOptionsTweakwise: [Array | null],
    showProductCount: {
      type: Boolean,
      required: false
    },
    productCount: {
      type: Number,
      required: true
    }
  });

const {
  productCount,
  productCountText,
  sortOptions,
  toggleSortDropdown,
  showSortDropdown,
  sortByText,
  sortByValue,
  selectedSortOrder
} = useListingSortWidget(props);
</script>