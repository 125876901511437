<template>
  <div class="account-order-history-wrapper">
    <!-- Filter -->
    <div 
      v-if="!showOrder && !notFullyShippedView && !showCreditsView"
      class="account-filter"
    >
      <div v-if="!showInvoiceList" class="col">
        <label>{{ $translate('ORDER_NUMBER') }}</label>
        <input v-model="orderNumber" type="text">
      </div>
      <div v-else class="col">
        <label>{{ $translate('C_INVOICE_NUMBER') }}</label>
        <input v-model="orderNumber" type="text">
      </div>
      
      <div class="col">
        <label>{{ $translate('C_FROM') }}</label>
        <input v-model="filterDateFrom" type="date">
      </div>
      
      <div class="col">
        <label>{{ $translate('C_TO') }}</label>
        <input v-model="filterDateTo" type="date">
      </div>
      
      
      <div class="col">
        <button 
          @click="clearFilters"
          type="button" 
          class="button small expanded clear-btn primary"
          :disabled="clearButtonInactive"
        >
          {{ $translate('BUTTON_CLEAR_FILTERS') }}
        </button>
      </div>
    </div>

    <div 
      v-if="!showOrder && !showInvoiceList && !notFullyShippedView && !showCreditsView"
    >
      <!-- Active orders -->
      <div class="order-table-heading-wrapper">
        <div class="table-heading">
          <h3>
            {{ $translate('C_ACTIVE_ORDERS') }} <span class="order-count">({{ filteredActiveOrdersCount }})</span>
          </h3>
        </div>
      </div>
  
      <div v-if="!activeOrders || !activeOrders.length">
        <p>{{ $translate('C_NO_ACTIVE_ORDERS') }}</p>
      </div>
  
      <div v-else>
        <table class="account-table">
          <thead>
            <th style="width: 20%;">{{ $translate('C_ORDER_ID') }}</th>
            <th style="width: 20%;">{{ $translate('ACCOUNT_ORDER_DATE') }}</th>
            <th style="width: 20%; text-align: right;" class="show-for-small-only">{{ $translate('C_ACCOUNT_ORDER_ORDERTOTAL_MOBILE') }}</th>
            <th style="width: 20%; text-align: right;" class="show-for-medium">{{ $translate('ACCOUNT_ORDER_ORDERTOTAL') }}</th>
            <th style="width: 20%;"></th>
          </thead>
          <tbody>
            <tr v-for="order in currentPageActiveOrderList" :key="order.number">
              <td>{{ order.number }}</td>
              <td>
                <span>{{ order.orderDate }}</span>  
              </td>
              <td style="text-align: right;" class="sum">{{ formatSum(order.totalAmountExcludingTax) }}</td>
              <td style="text-align: right;">
                <div class="flex-right">
                  <a @click="showOrderRows(order)">
                    <span class="table-link">
                      {{ $translate('HEADER_PRODUCTS_SHOW') }}
                    </span>
                  </a> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
  
        <pagination
          :number-of-pages="numberOfPagesActiveOrders"
          :selected-page="activeOrdersCurrentPage"
          @select-page="setCurrentPageActive($event)"
        ></pagination>
      </div>
    </div>

    <!-- Invoices -->
    <div v-if="showInvoiceList && !showOrder && !notFullyShippedView">
      <div class="order-table-heading-wrapper margin-large-top">
        <div class="table-heading">
          <h3>
            {{ $translate('C_INVOICE_HEADING') }} <span class="order-count">({{ filteredDeliveredOrdersCount }})</span>
          </h3>
        </div>
      </div>

      <div v-if="!deliveredOrders || !deliveredOrders.length">
        <p>{{ $translate('C_NO_INVOICE_HEADING') }}</p>
      </div>
  
      <div v-else class="table-container">
        <table class="account-table">
          <thead>
            <th style="width: 20%;">{{ $translate('C_INVOICE_NUMBER') }}</th>
            <th style="width: 20%;">{{ $translate('ORDER_NUMBER') }}</th>
            <th style="width: 20%;">{{ $translate('C_INVOICE_DATE') }}</th>
            <th style="width: 10%;" class="show-for-medium">{{ $translate('C_DUE_DATE') }}</th>
            <th style="width: 10%; text-align: right;" class="show-for-small-only">{{ $translate('C_ACCOUNT_ORDER_ORDERTOTAL_MOBILE') }}</th>
            <th style="width: 20%; text-align: right;" class="show-for-medium">{{ $translate('ACCOUNT_ORDER_ORDERTOTAL') }}</th>
            <th style="width: 20%; text-align: right;">{{ $translate('C_LEFT_TO_PAY') }}</th>
            <th style="width: 20%;"></th>
          </thead>
          <tbody>
            <tr v-for="order in currentPageInvoiceList" :key="order.number">
              <td>{{ order.number }}</td>
              <td>
                <span>{{ order.orderNumber }}</span>  
              </td>
              <td>
                <span>{{ order.invoiceDate }}</span>  
              </td>
              <td :class="{'due-date-passed': checkIfDueDatePassed(order.dueDate, order.remainingAmount)}" class="show-for-medium">{{ order.dueDate }}</td>
              <td style="text-align: right;" class="sum">{{ formatSum(order.totalAmountIncludingTax) }}</td>
              <td style="text-align: right;" class="sum">{{ formatSum(order.remainingAmount) }}</td>
              <td>
                <div class="flex-right">
                  <a @click="showInvoiceRows(order)" class="table-link">
                    {{ $translate('HEADER_PRODUCTS_SHOW') }}
                  </a> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Pagination -->
        <pagination
          :number-of-pages="numberOfPagesDeliveredOrders"
          :selected-page="deliveredOrdersCurrentPage"
          @select-page="setCurrentPageDelivered($event)"
        ></pagination>
      </div>
    </div>

    <AccountCreditsView 
      v-if="showCreditsView && !showOrder"
      @show-credit-rows="(credit) => {showCreditRows(credit)}"
    />

    <AccountShowOrderView
      v-if="showOrder && orderRows.length > 0 && !notFullyShippedView"
      @close-order="closeOrder()"
      :showOrder="showOrder"
      :orderRows="orderRows"
      :isInvoicePage="showInvoiceList"
      :isCreditsView="showCreditsView"
    />

    <AccountNotFullyShippedView
      v-if="notFullyShippedView && activeOrders.length"
      :activeOrders="activeOrders"
    />
    <div v-else-if="notFullyShippedView">
      <p>{{ $translate('C_NO_LISTED_ITEMS') }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import Pagination from '@/components/Pagination.vue';
import AccountShowOrderView from '@/components/AccountShowOrderView.vue';
import AccountCreditsView from '@/components/AccountCreditsView.vue';

export default {
  template: "#account-order-history",
  props: {
    externalId: {
      required: true,
      type: String,
    }
  },
  components: {
    Pagination,
    AccountShowOrderView,
    AccountCreditsView
  },
  
  setup(props) {
    const numberOfItemsPerPageActive = ref(10);
    const numberOfItemsPerPageDelivered = ref(10);
    const activeOrders = ref([]);
    let deliveredOrders = ref([]);
    let orderRows = ref([]);
    let activeOrdersCurrentPage = ref(1);
    let deliveredOrdersCurrentPage = ref(1);
    let filterDateFrom = ref("");
    let showOrder = ref("");
    let filterDateTo = ref("");
    let orderNumber = ref("");
    let showInvoiceList = ref(false);
    let notFullyShippedView = ref(null);
    let showCreditsView = ref(null);
    let creditList = ref([]);
    let currencyCode = 'SEK';
  

    // Check if invoice or order/order delivered view
    showInvoiceList.value = new URLSearchParams(window.location.search).get('show_invoice');

    // Check if AccountShowNotFullyShippedItems view
    notFullyShippedView.value = new URLSearchParams(window.location.search).get('fully_shipped');

    // Check if credits view
    showCreditsView.value = new URLSearchParams(window.location.search).get('show_credits');

    if (showCreditsView.value === '"true"') {
      showCreditsView.value = true;
    }

    const clearButtonInactive = computed(() => {
      if (filterDateTo.value || filterDateFrom.value || orderNumber.value) {
        return false;
      }
      return true;
    });

    const numberOfPagesActiveOrders = computed(() => {
      return Math.ceil(filteredActiveOrders.value.length / numberOfItemsPerPageActive.value);
    });

    const numberOfPagesDeliveredOrders = computed(() => {
      // this is used by invoices aswell
      return Math.ceil(filteredDeliveredOrders.value.length / numberOfItemsPerPageDelivered.value);
    });

    const filteredActiveOrdersCount = computed(() => {
      return filteredActiveOrders.value.length;
    });

    const filteredDeliveredOrdersCount = computed(() => {
      // this is used by invoices aswell
      return filteredDeliveredOrders.value.length;
    });

    const filteredActiveOrders = computed(() => {
      if (orderNumber.value) {
        return sortByDate(activeOrders.value.filter(order => order.number.includes(orderNumber.value)));
      }

      if (filterDateFrom.value || filterDateTo.value) {
        return sortByDate(filterListByDateSpan(activeOrders.value, filterDateFrom.value, filterDateTo.value));
      }

      return sortByDate(activeOrders.value);
    });

    const filteredDeliveredOrders = computed(() => {
      if (orderNumber.value) {
        return sortByDate(deliveredOrders.value.filter(order => order.orderNumber.includes(orderNumber.value)));
      }

      if (filterDateFrom.value || filterDateTo.value) {
        return sortByDate(filterListByDateSpan(deliveredOrders.value, filterDateFrom.value, filterDateTo.value));
      }

      return sortByDate(deliveredOrders.value);
    });

    const filteredInvoices = computed(() => {
      if (orderNumber.value) {
        return sortByDate(deliveredOrders.value.filter(order => order.number.includes(orderNumber.value)));
      }

      if (filterDateFrom.value || filterDateTo.value) {
        return sortByDate(filterListByDateSpan(deliveredOrders.value, filterDateFrom.value, filterDateTo.value));
      }

      return deliveredOrders.value;
    });

    const currentPageActiveOrderList = computed(() => {
      let rangeStart = (activeOrdersCurrentPage.value * numberOfItemsPerPageActive.value) - numberOfItemsPerPageActive.value;
      let rangeEnd = activeOrdersCurrentPage.value * numberOfItemsPerPageActive.value;
      return filteredActiveOrders.value.slice(rangeStart, rangeEnd);
    });

    const currentPageDeliveredOrderList = computed(() => {
      let rangeStart = (deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value) - numberOfItemsPerPageDelivered.value;
      let rangeEnd = deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value;
      return filteredDeliveredOrders.value.slice(rangeStart, rangeEnd);
    });
    
    const currentPageInvoiceList = computed(() => {
      let rangeStart = (deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value) - numberOfItemsPerPageDelivered.value;
      let rangeEnd = deliveredOrdersCurrentPage.value * numberOfItemsPerPageDelivered.value;
      return filteredInvoices.value.slice(rangeStart, rangeEnd);
    });

    const closeOrder = () => {
      showOrder.value = "";
      orderRows.value = [];
    };

    const filterListByDateSpan = (unfilteredList, filterDateFromValue, filterDateToValue) => {
      return unfilteredList.filter(order => {        
        let filterDateFrom = filterDateFromValue ? new Date(filterDateFromValue) : null;
        filterDateFrom = filterDateFrom ? filterDateFrom.getTime() : Number.NEGATIVE_INFINITY;
        
        let filterDateTo = filterDateToValue ? new Date(filterDateToValue) : null;
        filterDateTo = filterDateTo ? filterDateTo.getTime() : Number.POSITIVE_INFINITY;
        
        let orderDate = new Date(order.orderDate || order.invoiceDate);
        orderDate = orderDate.getTime();
        return orderDate >= filterDateFrom && orderDate <= filterDateTo;
      });
    };

    const setCurrentPageActive = (page) => {
      if (isNaN(page)) {
        return;
      }
      activeOrdersCurrentPage.value = page;
    };

    const langstr = (key) => {
      return window.language_variables[key];
    };

    const setCurrentPageDelivered = (page) => {
      if (isNaN(page)) {
        return;
      }
      deliveredOrdersCurrentPage.value = page;
    };

    const sortByDate = (arr) => {
      return arr.sort((a,b) => {
        let date1 = new Date(a.date).getTime();
        let date2 = new Date(b.date).getTime();
        return (date2 > date1 ? 1 : -1);
      });
    };

    const clearFilters = () => {
      filterDateFrom.value = "";
      filterDateTo.value = "";
      orderNumber.value = "";
    };

    const formatSum = (number) => {
      return new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2
      }).format(number);
    };

    const checkIfDueDatePassed = (dueDate, remainingAmount) => {
      let currentDate = new Date();
      let dueDateFormatted = new Date(dueDate);
      return (remainingAmount > 0 && currentDate > dueDateFormatted);
    };

    const fetchOrderList = async (key) => {
      const orderList = [];
      try {
  
        const body = new URLSearchParams({key});
        const response = await fetch('/ajax.php?mode=external_fetchdata', {
          method: 'POST',
          body: body
        });

        const data = await response.json();

        if (data.value[0].currencyCode != 'SEK') {
          currencyCode = data.value[0].currencyCode;
        }

        data.value.forEach(order => {
          orderList.push(order);
        });

        return orderList;

      } catch (error) {
        console.log(error);
      }
    };
    
    const fetchOrderRows = async (order, key, customFields) => {

      let requestData = new URLSearchParams({
        key,
        custom_fields: JSON.stringify(customFields)
      });

      try {
        const response = await fetch('/ajax.php?mode=external_fetchdata', {
          method: 'POST',
          body: requestData
        });

        const data = await response.json();
        orderRows.value = data.value;
        showOrder.value = order;
      } catch (error) {
        console.log(error);
      }
    }
    
    // Active orders
    const showOrderRows = (order) => {
      const customFields = { 'orderNo' : order.id };
      fetchOrderRows(order, 'FetchOrderRows', customFields);
    }

    // Delivered orders - same as invoices
    const showInvoiceRows = (order) => {
      const customFields = { 'invoiceNo' : order.id };
      fetchOrderRows(order, 'FetchInvoiceRows', customFields);
    }
    
    // Credits
    const showCreditRows = (credit) => {
      const customFields = { 'id' : credit.id };
      fetchOrderRows(credit, 'FetchCreditRows', customFields);
    }
    
    const updateOrders = async () => {
      activeOrders.value = await fetchOrderList('FetchOrderList');
      deliveredOrders.value = await fetchOrderList('FetchInvoiceList');
    }

    if (!showCreditsView.value) {
      updateOrders();
    }

    return {
      activeOrders,
      deliveredOrders,
      activeOrdersCurrentPage,
      deliveredOrdersCurrentPage,
      filterDateFrom,
      filterDateTo,
      orderNumber,
      clearButtonInactive,
      setCurrentPageActive,
      setCurrentPageDelivered,
      clearFilters,
      formatSum,
      numberOfPagesActiveOrders,
      numberOfPagesDeliveredOrders,
      filteredActiveOrdersCount,
      filteredDeliveredOrdersCount,
      currentPageActiveOrderList,
      currentPageDeliveredOrderList,
      orderRows,
      showOrder,
      showOrderRows,
      closeOrder,
      showInvoiceRows,
      filteredInvoices,
      currentPageInvoiceList,
      showCreditsView,
      creditList,
      showCreditRows,
      showInvoiceList,
      notFullyShippedView,
      checkIfDueDatePassed
    };
  },
};

</script>