<template>
  <div class="account-show-order-view-wrapper row column">
    <h2 v-if="!isInvoicePage && !isCreditsView">{{ $translate('ORDER_NUMBER') }} {{ showOrder.number }}</h2>
    <h2 v-else-if="!isCreditsView">{{ $translate('C_INVOICE') }} {{ showOrder.number }}</h2>

    <div class="order-info">
      <div>
        <span>{{ $translate('CUSTOMER_NUMBER') }}</span>
        <span>{{ showOrder.customerNumber }}</span>
      </div>
      <div>
        <span>{{ $translate('ACCOUNT_ORDER_DATE') }}</span>
        <span>{{ showOrder.orderDate ? showOrder.orderDate : showOrder.invoiceDate }}</span>
      </div>
      <div>
        <span>{{ $translate('C_REFERENCE') }}</span>
        <span>{{ showOrder.externalDocumentNumber }}</span>
      </div>
      <div v-if="!isCreditsView">
        <span>{{ $translate('C_YOUR_ORDER_NUMBER') }}</span>
        <span v-if="!isInvoicePage">{{ showOrder.number}}</span>
        <span v-else>{{ showOrder.orderNumber }}</span>
      </div>
      <div>
        <span>{{ $translate('C_DELIVERY_ADDRESS') }}</span>
        <span>{{ showOrder.shipToName }}</span>
        <span>{{ showOrder.shipToAddressLine1 }}</span>
        <span v-show="showOrder.shipToAddressLine2">{{ showOrder.shipToAddressLine2 }}</span>
        <div>
          <span>{{ showOrder.shipToPostCode }}</span>
          <span>{{ showOrder.shipToCity }}</span>
          <span>{{ showOrder.shipToCountry }}</span>
        </div>
      </div>

      <div 
        @click="$emit('close-order')"
        class="close-order" 
      >X</div>
    </div>


    <div 
      v-for="row in orderRows"
      :key="row.itemId"
      class="item-container-mobile show-for-small-only"
    >
      <div>
        <span>{{ $translate('C_ORDER_ROW_ARTICLECODE') }}</span>
        <span>{{ row.lineObjectNumber }}</span>
      </div>
      <div>
        <span>{{ $translate('TABLE_HEADING_PRODUCTS') }}</span>
        <span>{{ row.description }}</span>
      </div>
      <div>
        <span>{{ $translate('C_TABLE_HEADING_QUANTITY') }}</span>	
        <span>{{ row.quantity }}</span>
      </div>
      <div>
        <span>{{ $translate('TEXT_PRICE_PER_ITEM') }}</span>
        <span>{{ formatSum(row.unitPrice) }}</span>
      </div>
      <div>
        <span>{{ $translate('C_DISCOUNT') }}</span>
        <span>{{ row.discountPercent > 0 ? `${row.discountPercent}%` : '' }}</span>
      </div>
      <div v-if="!isCreditsView">
        <span>{{ $translate('C_ROW_SUM') }}</span>
        <span>{{ formatSum(row.netAmount) }}</span>
      </div>
      <div v-if="NotFullyShipped">
        <span>{{ $translate('C_LISTED_HEADING') }}</span>
        <span>{{ row.quantity - row.shippedQuantity }}</span>
      </div>
      <div>
        <span>{{ $translate('EXCL_VAT') }}</span>
        <span>{{ formatSum(row.amountExcludingTax) }}</span>
      </div>
      <div>
        <span>{{ $translate('INCL_VAT') }}</span>
        <span>{{ formatSum(row.amountIncludingTax) }}</span>
      </div>
    </div>

    <table class="account-table show-for-medium">
      <thead>
        <th>{{ $translate('C_ORDER_ROW_ARTICLECODE') }}</th>
        <th>{{ $translate('TABLE_HEADING_PRODUCTS') }}</th>
        <th>{{ $translate('TABLE_HEADING_QUANTITY') }}</th>
        <th>{{ $translate('TEXT_PRICE_PER_ITEM') }}</th>
        <th>{{ $translate('C_DISCOUNT') }}</th>
        <th v-if="!isCreditsView">{{ $translate('C_ROW_SUM') }}</th>
        <th v-if="NotFullyShipped">{{ $translate('C_LISTED_HEADING') }}</th>
        <th>{{ $translate('EXCL_VAT') }}</th>
        <th>{{ $translate('INCL_VAT') }}</th>
      </thead>
      <tbody>
        <tr v-for="row in orderRows" :key="row.number">
          <td>{{ row.lineObjectNumber }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.quantity }} {{ row.unitOfMeasureCode }}</td>
          <td>{{ formatSum(row.unitPrice) }}</td>
          <td>{{ row.discountPercent > 0 ? `${row.discountPercent}%` : '' }}</td>
          <td v-if="!isCreditsView">{{ formatSum(row.netAmount) }}</td>
          <td v-if="NotFullyShipped">{{ row.quantity - row.shippedQuantity }} {{ row.unitOfMeasureCode }}</td>
          <td>{{ formatSum(row.amountExcludingTax) }}</td>
          <td>{{ formatSum(row.amountIncludingTax) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="total-box">
      <div class="total-box-inner">
        <div v-if="showOrder.discountAmount > 0" class="total-box-inner-row">
          <span>{{ $translate('C_TOTAL_DISCOUNT') }}</span>
          <span class="sum">{{ formatSum(showOrder.discountAmount) }}</span>
        </div>
        <div class="total-box-inner-row">
          <span>{{ $translate('C_TOTAL_EXCLUDING_VAT') }}</span>
          <span class="sum">{{ formatSum(showOrder.totalAmountExcludingTax) }}</span>
        </div>
        <div class="total-box-inner-row">
          <span>{{ $translate('C_TOTAL_INCLUDING_VAT') }}</span>
          <span class="sum">{{ formatSum(showOrder.totalAmountIncludingTax) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    showOrder: {
      required: true,
      type: Object
    },
    orderRows: {
      required: true,
      type: Array
    },
    isInvoicePage: {
      required: true,
      type: Boolean
    },
    isCreditsView: {
      required: true,
      type: Boolean
    }
  })

  const NotFullyShipped = computed(() => {
    return props.orderRows.some(row => row.shippedQuantity != undefined);
  });

  const formatSum = (number) => {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: props.showOrder.currencyCode,
      minimumFractionDigits: 2
    }).format(number);
  };

</script>