<template>
  <input 
    v-model="model"
    @change="validateProductQuantityChange"
    :step="props.step"
    type="number"
    name="quantity"
    ref="quantityInput"
    :class="{ 'hide-spin-buttons': props.hideSpinButtons }"
    :title="$translate('TABLE_HEADING_QUANTITY')"
    min="0"
  />
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  inputValue: {
    type: Number,
    required: false
  },
  modelValue: {
    type: [Number, String],
    required: false
  },
  step: {
    type: Number,
    required: false,
    default: 1
  },
  lowestNumber: {
    type: Number,
    required: false,
    default: 1
  },
  packageSize: {
    type: [Number, String],
    required: false,
    default: 1
  },
  hideSpinButtons: {
    type: Boolean,
    required: false,
    default: false
  },
  isCart: {
    type: Boolean,
    required: false,
    default: false
  }
});

const quantityInput = ref();
const emit = defineEmits(['update:modelValue', 'quantityChange'])

const decreaseQuantity = () => {
  let min = props.packageSize > 1 ? props.packageSize : 1;
  quantityInput.value.stepDown(min);
  emit('update:modelValue', quantityInput.value.value);
  emit('quantityChange', quantityInput.value.value)
}
const increaseQuantity = () => {
  let min = props.packageSize > 1 ? props.packageSize : 1;
  quantityInput.value.stepUp(min);
  emit('update:modelValue', quantityInput.value.value);
  emit('quantityChange', quantityInput.value.value);
}

defineExpose({
  increaseQuantity,
  decreaseQuantity
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const validateProductQuantityChange = () => {      
  /** Here you can add validation to quantity changes.
   * For example if you want to add logic to handle purchase intervals
    */

  if (!props.isCart) {
    if (model.value < props.packageSize) {
      model.value = props.packageSize;
    } else if (model.value % props.packageSize !== 0) {
      model.value = Math.ceil(model.value / props.packageSize) * props.packageSize;
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-spin-buttons {
  /* Works for Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
</style>