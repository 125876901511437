<template>
  <div class="account-show-order-view-wrapper row column">
    <h2>{{ $translate('C_LISTED_HEADING') }}</h2>
    <div 
      v-for="row in listedRows"
      :key="row.id"
      class="item-container-mobile show-for-small-only"
    >
      <div>
        <span>{{ $translate('C_ORDER_ROW_ARTICLECODE') }}</span>
        <span>{{ row.lineObjectNumber }}</span>
      </div>
      <div>
        <span>{{ $translate('TABLE_HEADING_PRODUCTS') }}</span>
        <span>{{ row.description }}</span>
      </div>
      <div>
        <span>{{ $translate('TABLE_HEADING_QUANTITY') }}</span>	
        <span>{{ row.quantity }}</span>
      </div>
      <div>
        <span>{{ $translate('C_LISTED_HEADING') }}</span>
        <span>{{ row.quantity - row.shippedQuantity }}</span>
      </div>
      <div>
        <span>{{ $translate('C_ALLOCATED') }}</span>
        <span>{{ row.invoiceDiscountAllocation }}</span>
      </div>
      <div>
        <span>{{ $translate('ACCOUNT_ORDER_DATE') }}</span>
        <span>{{ row.orderDate }}</span>
      </div>
      <div>
        <span>{{ $translate('C_ORDER_ID') }}</span>
        <span>{{ row.number }}</span>
      </div>
      <div>
        <span>{{ $translate('C_YOUR_ORDER_NUMBER') }}</span>
        <span>{{ row.number }}</span>
      </div>
      <div>
        <span>{{ $translate('C_ESTIMATED_DELIVERY') }}</span>
        <span>{{ row.shipmentDate }}</span>
      </div>
    </div>

    <table class="account-table show-for-medium">
      <thead>
        <th>{{ $translate('C_ORDER_ROW_ARTICLECODE') }}</th>
        <th>{{ $translate('TABLE_HEADING_PRODUCTS') }}</th>
        <th>{{ $translate('TABLE_HEADING_QUANTITY') }}</th>
        <th>{{ $translate('C_LISTED_HEADING') }}</th>
        <th>{{ $translate('C_ALLOCATED') }}</th>
        <th>{{ $translate('ACCOUNT_ORDER_DATE') }}</th>
        <th>{{ $translate('C_ORDER_ID') }}</th>
        <th>{{ $translate('C_YOUR_ORDER_NUMBER') }}</th>
        <th>{{ $translate('C_ESTIMATED_DELIVERY') }}</th>
      </thead>
      <tbody>
        <tr v-for="row in listedRows" :key="row.itemId">
          <td>{{ row.lineObjectNumber }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.quantity }}</td>
          <td>{{ row.quantity - row.shippedQuantity }}</td>
          <td>{{ row.invoiceDiscountAllocation }}</td>
          <td>{{ row.orderDate }}</td>
          <td>{{ row.number }}</td>
          <td>{{ row.number }}</td>
          <td>{{ row.shipmentDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
  import { computed, ref } from "vue";

  const props = defineProps({
    activeOrders: {
      required: true,
      type: Object
    },
  });

  const listedRows = ref([]);

  const checkIfListedItems = (orderRows, order) => {

    orderRows.forEach((row) => {
      if (row.quantity > row.shippedQuantity) {
        // item.ordernumber = orderNo;
        row.number = order.number 
        row.orderDate = order.orderDate 
        listedRows.value.push(row);
      }
    });

  };

  const fetchOrderRows = async (orderProxy, key, customFields) => {
    let order = JSON.parse(JSON.stringify(orderProxy));

    let requestData = new URLSearchParams({
      key,
      custom_fields: JSON.stringify(customFields)
    });

    try {
      const response = await fetch('/ajax.php?mode=external_fetchdata', {
        method: 'POST',
        body: requestData
      });

      const data = await response.json();
      
      checkIfListedItems(data.value, order);

    } catch (error) {
      console.log(error);
    }
  };

  props.activeOrders.forEach((order) => {
    const customFields = { 'orderNo' : order.id };
    fetchOrderRows(order, 'FetchOrderRows', customFields);
  });

</script>